
import { defineComponent } from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { mapState as mapStatePinia } from "pinia";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Card from "primevue/card";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import Textarea from "primevue/textarea";
import Divider from "primevue/divider";
import CheckBox from "primevue/checkbox";
import CustomAccordion from "@/components/UI/CustomAccordion.vue";
import AccordionTab from "primevue/accordiontab";
import SplitButton from "primevue/splitbutton";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import store from "@/store";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
import CustomFields from "@/components/UI/CustomFields.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputSwitch from "primevue/inputswitch";
import { useContactControlStore } from "@/store/data/controls/contact";

import ContactAutocomplete from "@/components/Autocompletes/Contact.vue";
import Utils from "@/utility/utils";
import Signature from "@/components/UI/Signature.vue";
import SearchPartDialog from "@/components/Sales/Orders/SearchPartDialog.vue";
import RepDataTable from "@/components/Reps/RepDataTable.vue";
import SearchCustomer from "@/components/Search/SearchCustomer.vue";

//types
import Customer, { basecontact } from "@/types/customer";
import Contact from "@/types/contact";
import SOQuote, { LiItems, QtyItems } from "@/types/soquote";
import SalesOrder, { LisItems } from "@/types/salesorder";
import { Fdict, Field } from "@/types/fdict";
import Part from "@/types/part";

// Services
import ContactService from "@/services/ContactService";
import PartsService from "@/services/PartsService";
import SalesService from "@/services/SalesService";
import SoQuoteService from "@/services/SOQuotesService";
import CustomerService from "@/services/CustomerService";

import { FDICT_SO } from "@/utility/fdicts/so";

const customerService = new CustomerService();
const salesService = new SalesService();
const partService = new PartsService();
const soQuoteService = new SoQuoteService(process.env.VUE_APP_ABSTRACTION_API);
const contactService = new ContactService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: "Order tab",
  async created() {
    if (!this.$attrs.orderId) {
      this.$router.push("/sales/orders");
    }
    let id = this.$attrs.orderId as string;
    this.currentOrder = await this.getActiveOrderTab(id);
    this.currentId = id;

    if (this.currentOrder) {
      this.newSalesOrderCust = this.currentOrder.cust_name
        ? this.currentOrder.cust_name
        : "";
      if (
        this.currentOrder.lis_items &&
        this.currentOrder.lis_items.length &&
        this.currentOrder.lis_items[0].li_sched_dates_items
      ) {
        this.scheduleDate =
          this.currentOrder.lis_items[0].li_sched_dates_items[0].li_sched_dates;
      }
      if (this.currentOrder.lis_items) {
        this.currentOrder.lis_items = this.currentOrder.lis_items.map(
          (item) => ({
            ...item,
            wrap_desc: item.wrap_desc?.replaceAll("^", " "),
          }),
        );
        this.li_ship_dates = this.currentOrder.lis_items.map(
          (item) =>
            item.li_ship_nos_items &&
            item.li_ship_nos_items.length > 0 &&
            item.li_ship_nos_items[0].li_ship_dates,
        );
      } else {
        this.currentOrder.lis_items = [];
      }
      delete this.currentOrder.oldRecord;
      const oldRecord = JSON.parse(JSON.stringify(this.currentOrder));
      this.currentOrder["oldRecord"] = oldRecord;

      this.newSalesOrderContact = this.currentOrder.contact;
    }
    this.salesOrderFdict = (await this.getFdicts("SO")) as Fdict;

    this.updateCustomFieldLineItems();

    this.initControls();
    if (store.getters["customerInquiry/getTerms"] == null) {
      await store.dispatch("customerInquiry/getTerms");
    }

    if (this.getCats === null || this.getCats.length === 0) {
      await this.fetchControls({
        Client: "",
        id: "CAT",
        procedure: "CAT.CONTROL",
        filename: "CONTROL",
        getter: "control/getCategoryItems",
      });
    }

    if (this.getTypes === null || this.getTypes?.length === 0) {
      const coCode = this.currentOrder.co_code ? this.currentOrder.co_code : "";
      this.getTypeControls(coCode);
    }

    if (
      this.currentOrder &&
      !this.currentOrder?.bill_to_name &&
      this.currentOrder.bill_to
    ) {
      this.getBillToInfo(this.currentOrder.bill_to);
    }

    if (this.currentOrder?.contact_id && !this.currentOrder.contact) {
      this.getContact(this.currentOrder.contact_id);
    }

    if (this.currentOrder?.co_code) {
      this.getTypeControls(this.currentOrder.co_code);
    }

    this.fetchSalesTaxCodes();
  },
  mounted() {
    let id = this.$attrs.orderId as string;

    this.currentId = id;
    this.currentOrder = this.getActiveOrderTab(id);
    this.updateCustomFieldLineItems();
  },
  async updated() {
    let id = this.$attrs.orderId as string;

    this.currentId = id;
    this.currentOrder = this.getActiveOrderTab(id);
    this.newSalesOrderCust =
      this.currentOrder !== null && this.currentOrder.cust_name
        ? this.currentOrder.cust_name
        : "";
    this.currentId =
      (this.currentOrder && (this.currentOrder.so_id as string)) || id;

    if (!this.currentOrder.customer && this.currentOrder.sold_to) {
      customerService
        .getCustomer(
          this.currentOrder.sold_to,
          store.getters["session/getClient"],
        )
        .then((resp) => {
          this.currentOrder.customer = resp as Customer;
        });
    }
  },

  components: {
    ContactAutocomplete,
    InputText,
    Dropdown,
    Calendar,
    Button,
    Dialog,
    InputNumber,
    Signature,
    Divider,
    Card,
    CheckBox,
    CustomAccordion,
    AccordionTab,
    SearchPartDialog,
    Textarea,
    SplitButton,
    EmailFileDialog,
    CustomFields,
    RepDataTable,
    SearchCustomer,
    DataTable,
    Column,
    InputSwitch,
  },

  methods: {
    ...mapActions({
      findCurrentOrder: "salesInquiry/findOpenedOrder",
      findCurrentSale: "salesInquiry/findOpenedSalesInquiry",
      getFdicts: "fdict/fetchFdict",
      removeSaleInquiryTab: "salesInquiry/removeOpenedSalesInquiry",
      removeOrderTab: "salesInquiry/removeOpenedOrder",
      addOpenedLineItem: "salesInquiry/addOpenedLineItem",
      fetchControls: "control/fetchControl",
      getOrderPDF: "sales/getOrderPDF",
      addNotification: "notification/add",
      setPosCustomer: "pos/setCustomer",
      getMrkControl: "mrkControl/getMrkControl",
      fetchSalesTaxCodes: "stax/fetchSalesTaxCodes",
    }),
    calculateExtendedPrice(lineItem: LisItems): string {
      const price = parseFloat(lineItem.li_prices);
      const qty = parseFloat(lineItem.li_order_qtys);
      const discount = parseFloat(lineItem.li_discs || "0") || 0.0;
      const value = ((price - price * discount) * qty).toFixed(2);

      if (value === "NaN") {
        return "";
      } else {
        return "$" + value;
      }
    },
    setCustomFieldData(data: any, lineItem: any, field: Field) {
      const name: string = field.json_name as string;
      if (field.conv && field.conv[0] === "D") {
        lineItem.custom_fields[name] = Utils.formatDate(data);
        lineItem[name] = Utils.formatDate(data);
      } else if (field.conv && field.conv[0] === "M" && field.conv[1] === "D") {
        lineItem.custom_fields[name] = data.toString();
        lineItem[name] = data.toString();
      } else {
        lineItem.custom_fields[name] = data;
        lineItem[name] = data;
      }
    },
    setNumberData(data: any, field: string) {
      data[field] = data[field].toString();
    },
    getLineItemDate(lineItem: any) {
      if (
        !lineItem.li_sched_dates_items ||
        lineItem.li_sched_dates_items.length === 0
      ) {
        return "";
      }

      const dates = lineItem.li_sched_dates_items.map((item: any) => {
        return item.li_ship_dates;
      });

      if (dates.length === 1) {
        return dates[0];
      }

      return "MULTIPLE";
    },
    setLineItemDate(event: any, lineItem: any) {
      const date = Utils.formatDate(event);

      if (
        !lineItem.li_sched_dates_items ||
        lineItem.li_sched_dates_items.length === 0
      ) {
        lineItem.li_sched_dates_items = [{ li_ship_dates: date }];
      }

      if (
        lineItem.li_sched_dates_items &&
        lineItem.li_sched_dates_items.length === 1
      ) {
        lineItem.li_sched_dates_items[0].li_ship_dates = date;
      }
    },
    updateCustomFields(data: any) {
      this.currentOrder.custom_fields = data;
    },
    updateReps(reps: any) {
      this.currentOrder.rep_items = reps;
    },
    handleSendEmail(data: any) {
      salesService
        .getOrderPDF(this.currentOrder.so_id, this.getClient, data)
        .then((response: any) => {
          if (response === "success") {
            this.addNotification({
              message: `Sales Order ${this.currentOrder.so_id} has been emailed successfully`,
              type: "success",
            });
          } else {
            this.addNotification({
              message: `Sales Order was not sent`,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.addNotification({
            message: `Sales Order could not be sent: ${error}`,
            type: "error",
          });
        })
        .finally(() => {
          this.showEmailDialog = false;
        });
    },
    handleEmailPDF() {
      this.showEmailDialog = true;
    },
    getSaleOrderPDF() {
      this.getOrderPDF({
        client: this.getClient,
        recordId: this.currentOrder.so_id,
      });
    },
    getPDFBtnTitle(id: string) {
      let downloading = false;
      downloading =
        this.getLoadingPDFs.find((i: string) => i === id) !== undefined;
      return downloading ? "Downloading" : "Download";
    },
    handlePDFIcons(id: string) {
      let downloading = false;
      downloading =
        this.getLoadingPDFs.find((i: string) => i === id) !== undefined;
      return {
        "pi pi-download": !downloading,
        "pi pi-spin pi-spinner": downloading,
      };
    },
    addItem(item: any) {
      const today = new Date();
      this.part = item;
      this.formatDate(today, "PART");
      this.addPart(this.part);
    },
    closeSearchPartDialog(event: any) {
      this.showSearchPartDialog = event;
    },
    onIsSearchingParts(event: boolean) {
      this.isSearchingParts = event;
    },
    handleRemoveDetailItem(data: any) {
      const index = this.currentOrder.lis_items.findIndex(
        (item: any) => item.lis === data.lineItem.lis,
      );
      this.currentOrder.lis_items = this.currentOrder.lis_items.filter(
        (item: any) => item.lis !== data.lineItem.lis,
      );
      this.li_ship_dates.splice(index, 1);
    },
    handleDetailItemButton(data: any) {
      this.addOpenedLineItem({ ...data });
      this.$router.push(
        `/sales/orders/${data.orderId}/line-item/${data.lineItem.lis}`,
      );
    },
    async getTypeControls(co_code: string) {
      await this.getMrkControl({
        client: store.getters["session/getClient"],
        selectedCode: co_code,
      });
      this.validateSalesOrder();
    },
    handleContactSelected(contact: any) {
      this.getContact(contact.contact_id);
    },
    openAddExistingQuoteDialog() {
      this.showAddExistingQuoteDialog = true;
    },
    openAddExistingOrderDialog() {
      this.showAddExistingOrderDialog = true;
    },
    setPart(event: any) {
      this.part.number = event.value.part_no;
    },
    setBillTo(element: any) {
      this.isBillToLoading = true;
      this.currentOrder.bill_to_name = element.name as string;
      this.currentOrder.bill_to = element.cust_id;
      this.isBillToLoading = false;
    },
    getBillToInfo(bill_to_id: string) {
      this.isBillToLoading = true;
      customerService
        .getCustomer(bill_to_id, store.getters["session/getClient"])
        .then((response) => {
          const cust = response as Customer;
          this.currentOrder.bill_to_name = cust.name as string;
          this.currentOrder.bill_to = cust.cust_id as string;
        })
        .finally(() => {
          this.isBillToLoading = false;
        });
    },
    validateSalesOrder() {
      if (this.allowValidation) {
        const copy = JSON.parse(JSON.stringify(this.currentOrder));
        if (copy.so_id.includes("New")) {
          copy.so_id = "";
          copy.oldRecord.so_id = "";
        }
        salesService
          .validateSalesOrder({
            newSalesOrder: copy,
            oldSalesOrder: copy.oldRecord,
            user: store.getters["session/getUser"].user_id,
            metaData: "VALIDATE.ONLY",
            returnRecord: "Y",
          })
          .then((resp: any) => {
            if (resp.record) {
              this.currentOrder = resp.record;
              this.currentOrder.oldRecord = JSON.parse(
                JSON.stringify(resp.record),
              );
            }
          });
      }
    },
    setSoldTo(element: any) {
      if (element.cust_id === this.currentOrder.sold_to) {
        return;
      }

      this.isLoadingCustomer = true;

      customerService
        .getCustomer(element.cust_id, store.getters["session/getClient"])
        .then((response) => {
          const cust = response as Customer;
          this.currentOrder["customer"] = cust;

          if (!this.currentOrder.so_id.includes("New")) {
            // Don't change the data if the order is not new;
            return;
          }

          this.setPosCustomer({ cust_items: [cust] });
          this.currentOrder.bill_to = cust.bill_to as string;
          this.salesContact(cust.contact_id_items as Array<basecontact>);
          this.currentOrder.sold_to = cust.cust_id as string;
          /*cust code is the price code*/
          if (cust.code != null || cust.code != undefined) {
            this.currentOrder.cust_code = cust.code as string;
          }
          if (cust.disc != null || cust.disc != undefined) {
            this.discount = parseFloat(cust.disc);
          }
          if (cust.resale != null || cust.resale != undefined) {
            this.currentOrder.resale_number = cust.resale;
          }
          if (element.newSalesOrderCust) {
            this.newSalesOrderCust = cust.name as string;
          }
          if (cust.cust_id) {
            this.currentOrder.cust_id = element.cust_id as string;
            this.currentOrder.cust_name = cust.name as string;
            this.newSalesOrderCust = cust.name as string;
          }

          if (cust.terms) {
            this.currentOrder.terms_code = cust.terms as string;
          } else {
            this.currentOrder.terms_code = "";
          }

          if (cust.contact_id_items && cust.contact_id_items.length > 0) {
            this.currentOrder.contact_id = cust.contact_id_items[0]
              .contact_id as string;
            this.currentOrder.contact = cust.contact_id_items[0]
              .contact_name as string;
            this.currentOrder.email = cust.contact_id_items[0]
              .contact_email as string;
          } else {
            this.currentOrder.contact_id = "";
          }

          if (cust.bill_to) {
            this.getBillToInfo(cust.bill_to);
          } else {
            this.currentOrder.bill_to_name = "";
          }

          if (cust.rep_items) {
            this.currentOrder.rep_items = [...cust.rep_items] as any;
          } else {
            this.currentOrder.rep_items = [];
          }
          Utils.setOrderAddress(
            cust,
            this.currentOrder,
            null,
            this.getSalesTaxCodes,
          );
        })
        .finally(() => {
          this.isLoadingCustomer = false;
        });
    },
    getContact(contact_id: string) {
      this.isContactLoading = true;
      contactService
        .getContacts(contact_id, store.getters["session/getClient"])
        .then((response: any) => {
          const contact = response?.contact_items[0] || ({} as Contact);
          if (contact.contact_id != this.currentOrder.contact_id) {
            this.currentOrder.contact_id = contact.contact_id;
          }
          this.currentOrder.contact =
            contact.first_name + " " + contact.last_name;
          this.newSalesOrderContact =
            contact.first_name + " " + contact.last_name;
          if (
            contact.telephone_items != null ||
            contact.telephone_items != undefined
          ) {
            this.currentOrder.phone = contact.telephone_items[0].telephone;
          } else {
            this.currentOrder.phone = "";
          }

          if (
            contact.email_address_items != null ||
            contact.email_address_items != undefined
          ) {
            this.currentOrder.contact_email =
              contact.email_address_items[0].email_address || "";
          } else {
            this.currentOrder.contact_email = "";
          }
        })
        .finally(() => {
          this.isContactLoading = false;
          this.showAddExistingQuoteDialog = false;
        });
    },
    salesContact(contacts: Array<basecontact>) {
      if (contacts) {
        contacts.forEach((contact: basecontact) => {
          if (contact.contact_type == "SO") {
            this.getContact(contact.contact_id as string);
          }
        });
        if (this.currentOrder.contact) {
          this.newSalesOrderContact = "";
          this.currentOrder.contact_email = "";
          this.currentOrder.phone = "";
          this.currentOrder.contact = "";
        }
      }
    },
    initCust() {
      this.isLoadingCustomer = true;
      const cust = this.customer as Customer;
      this.currentOrder.sold_to = cust.cust_id as string;
      this.currentOrder.terms_code = cust.terms as string;
      this.currentOrder.cust_code = cust.code as string;
      this.currentOrder.bill_to = cust.bill_to as string;
      const contact = "";
      this.currentOrder.contact = contact != "" ? contact : "";
      this.currentOrder.resale_number = cust.resale as string;
      this.discount = parseFloat(cust.disc as string) || 0;
      this.newSalesOrderCust = cust.name as string;
      this.salesContact(cust.contact_id_items);
      this.isLoadingCustomer = false;
    },
    initControls() {
      const client = store.getters["session/getClient"];
      const CONTROLS = [
        {
          Client: client,
          control: "price",
          id: "PRICE",
          filename: "CONTROL",
          procedure: "price.CONTROL",
          getter: "getPricingCodes",
        },
        {
          Client: client,
          id: "MRK",
          procedure: "MRK.CONTROL",
          filename: "CONTROL",
          getter: "getHoldCodes",
        },
        {
          Client: client,
          id: "PLAN",
          procedure: "PLAN.CONTROL",
          filename: "CONTROL",
          getter: "getPlanGroupCodes",
        },
      ];

      CONTROLS.forEach((element: any) => {
        if (
          store.getters[`customerInquiry/${element.getter}`] == null ||
          store.getters[`customerInquiry/${element.getter}`]?.length === 0
        ) {
          store.dispatch("customerInquiry/getControls", element);
        }
      });
    },
    formatDate(date: Date | string, field: string) {
      const formattedString = Utils.formatDate(date);
      switch (field) {
        case "PART":
          this.part.date = formattedString;
          break;
        case "CLOSE_DATE":
          this.currentOrder.close_date = formattedString;
          break;
        case "HOLD_DATE":
          this.currentOrder.hold_date = formattedString;
          break;
        case "SCHEDULE_DATE":
          this.scheduleDate = formattedString;
          break;
        case "BOOK_DATE":
          this.currentOrder.book_date = formattedString;
          break;
        case "ORDER_DATE":
          this.currentOrder.date = formattedString;
          break;
        case "LAST_DATE":
          this.currentOrder.last_date = formattedString;
          break;
      }
    },
    async validatePartReq() {
      this.isFormSubmitted = true;
      const isFormCorrect = await this.v$.currentOrder.$validate();
      if (isFormCorrect) {
        this.showPartModal = true;
      }
    },
    addSelectedParts(parts: Array<any>) {
      parts.forEach((part) => {
        const qty = part.qty.toString();
        const id = Utils.getNewSalesOrderLineItemId(this.currentOrder);
        const lineItem = {
          lis: id,
          li_parts: part.part,
          li_order_qtys: qty,
          li_prices: part.price,
          li_sched_dates_items: [
            {
              li_sched_qtys: qty,
            },
          ],
        } as LisItems;
        this.currentOrder.lis_items.push(lineItem);
      });
    },
    clearPart() {
      this.part.quantity = 1;
      this.part.number = "";
      this.part.date = "";
    },
    async submit() {
      this.updateCustomFieldLineItems();
      this.isFormSubmitted = true;

      const isCustomerFieldPopulated =
        await this.v$.newSalesOrderCust.$validate();
      const isPartFieldPopulated = await this.v$.lis_items.$validate();

      if (this.currentOrder.so_id && !this.currentOrder.so_id.includes("New")) {
        this.submitLoading = true;
        const oldData = JSON.parse(
          JSON.stringify(this.currentOrder.oldRecord ?? {}),
        );
        delete oldData.saleType;
        delete this.currentOrder.oldRecord;
        const data = JSON.parse(JSON.stringify(this.currentOrder));

        const payload = {
          oldSalesOrder: oldData,
          newSalesOrder: data,
        };
        salesService
          .updateOrder(this.currentOrder.so_id, payload)
          .then((response: any) => {
            if (response.recordId) {
              this.currentOrder.so_id = response.recordId;
            }
            delete this.currentOrder.oldRecord;
            this.currentOrder = JSON.parse(JSON.stringify(data));
            this.updateCustomFieldLineItems();
            this.currentOrder.oldRecord = JSON.parse(JSON.stringify(oldData));
            const notification = {
              message: `Successfully Updated Order #${this.currentOrder.so_id}.`,
              type: "success",
            };
            store.dispatch("notification/add", notification);
            this.$router.push("/sales/orders");
          })
          .catch((error) => {
            this.isFormSubmitted = false;
          })
          .finally(() => {
            this.submitLoading = false;
          });
      } else if (isCustomerFieldPopulated && isPartFieldPopulated) {
        this.submitLoading = true;

        const oldData = JSON.parse(
          JSON.stringify(this.currentOrder.oldRecord ?? {}),
        );
        delete oldData.saleType;

        const data = JSON.parse(JSON.stringify(this.currentOrder));
        delete data.oldRecord;
        delete data.saleType;
        data.so_id = "";

        salesService
          .postOrder(data)
          .then((response: any) => {
            this.submitLoading = false;
            let saleId = response.recordId;
            delete this.currentOrder.oldRecord;
            this.currentOrder.oldRecord = JSON.parse(
              JSON.stringify(this.currentOrder),
            );
            this.currentOrder.so_id = saleId;
            this.currentOrder.oldRecord.so_id = saleId;
            const notification = {
              message: `Successfully Created Sale Id ${saleId}.`,
              type: "success",
            };
            store.dispatch("notification/add", notification);
            this.$router.push("/sales/orders");
          })
          .catch((error) => {
            this.submitLoading = false;
            this.isFormSubmitted = false;
          });
      } else {
        this.isFormSubmitted = false;
        const notification = {
          message: "Please fill Customer and Parts fields",
          type: "error",
        };
        store.dispatch("notification/add", notification);
      }
    },
    calculateOrderTotalSum() {
      let total = 0;
      this.currentOrder.lis_items.forEach((item) => {
        total +=
          (parseFloat(item.li_prices) || 0) *
          (parseFloat(item.li_order_qtys) || 0);
      });
      this.currentOrder.order_total_sum = total.toString();
    },
    updateCustomFieldLineItems() {
      if (!this.currentOrder.lis_items) {
        this.currentOrder.lis_items = [];
      }
      this.currentOrder.lis_items = this.currentOrder.lis_items.map(
        (item: any) => {
          const lineItemCustomFields = {} as any;
          Object.keys(this.customFieldsLineItems).map((field: any) => {
            const name = this.customFieldsLineItems[field].json_name;
            lineItemCustomFields[name] = item[name] ?? "";
          });

          item.custom_fields = lineItemCustomFields;
          return item;
        },
      );
    },
    async addPart(data?: any) {
      const price = data.price;
      const desc = data.desc || "";
      this.isFormSubmitted = true;
      const isFormCorrect = await this.v$.part.$validate();
      if (isFormCorrect || data) {
        this.partLoading = true;

        const foundPart = this.currentOrder.lis_items.find((item) => {
          return item.li_parts.toLowerCase() == data.part_number.toLowerCase();
        });

        let qty = "1";
        let updatePart = false;
        if (
          foundPart &&
          foundPart.li_sched_dates_items[0].li_sched_dates === data.date
        ) {
          qty = (+foundPart.li_order_qtys + data.quantity).toString();
          updatePart = true;
        } else {
          qty = data.quantity.toString();
        }

        await partService
          .getPart(
            store.getters["session/getClient"],
            data.part_number,
            this.currentOrder.sold_to,
            "",
          )
          .then((resp) => {
            const part = resp as Part;
            if (updatePart && foundPart) {
              foundPart.li_order_qtys = qty;
              foundPart.li_prices = price;
              foundPart.li_sched_dates_items[0].li_sched_qtys = qty;
            } else {
              const id = Utils.getNewSalesOrderLineItemId(this.currentOrder);
              const lineItem = {
                lis: id,
                li_parts: part.part_no,
                li_revs: part.rev,
                li_taxables: part.taxable,
                li_order_qtys: qty,
                li_prices: price,
                li_discs: this.discount.toString(),
                li_disc_amts: (this.discount * parseFloat(price)).toString(),
                li_sched_dates_items: [
                  {
                    li_sched_dates: Utils.formatDate(new Date()),
                    li_sched_qtys: qty,
                  },
                ],
                wrap_desc: desc.replaceAll("^", " "),
                li_sales_accts: this.getSalesAcct,
                li_fg_locs: this.getFgLoc,
                cg_loc: this.getCgLoc,
              } as LisItems;
              lineItem.custom_fields = {};
              this.currentOrder.lis_items.push(lineItem);
            }

            this.clearPart();
            this.partLoading = false;
          });
        this.validateSalesOrder();
      }
    },
    async populateDialog() {
      this.isLoadQuoteFormSubmitted = true;

      const isValidQuoteInput = await this.v$.existingSOQuote.$validate();
      if (isValidQuoteInput) {
        this.isLoadingQuote = true;
        this.isOrderEmpty = false;
        this.statusMessage = "";
        soQuoteService
          .getSOQuotes(this.existingSOQuote)
          .then((response) => {
            if ((response as any).soquote_items?.length === 1) {
              const SOQuoteRecord = (response as any)
                .soquote_items[0] as SOQuote;

              this.mapQuoteToOrder(SOQuoteRecord, this.copyOnlyQuoteParts);
            } else {
              this.isOrderEmpty = true;
              const notification = {
                message: `Quote ${this.existingSOQuote} does not exist.`,
                type: "error",
              };
              store.dispatch("notification/add", notification);
            }
          })
          .catch(() => {
            this.statusMessage = "Failed to load quote";
          })
          .finally(() => {
            this.isLoadingQuote = false;
            this.isLoadQuoteFormSubmitted = false;
          });
      }
    },
    getOrderById(orderId: string, correls?: string) {
      const correl = correls
        ? correls
        : "cust_name bill_to_name hold_code_desc order_amount cust_resale currency_code exchange_rate pending_ship";
      return salesService.getOrderById(orderId, correl);
    },
    async populateDialogWithOrder() {
      this.isLoadOrderFormSubmitted = true;

      const isValidQuoteInput = await this.v$.existingOrder.$validate();

      if (isValidQuoteInput) {
        this.isLoadingOrder = true;
        this.isOrderEmpty = false;
        this.statusMessage = "";

        this.getOrderById(this.existingOrder)
          .then((response: any) => {
            this.isLoadingOrder = false;

            if (response !== null) {
              if (this.copyOnlyOrderParts === "Y") {
                const li_items = response.lis_items as Array<LisItems>;
                this.currentOrder.lis_items = li_items;
              } else {
                const orderRecord = response as SalesOrder;
                orderRecord.so_id = this.currentOrder.so_id;
                this.mapExistingOrderToOrder(orderRecord);

                if (this.currentOrder.co_code) {
                  this.getTypeControls(this.currentOrder.co_code);
                }
              }
              this.showAddExistingOrderDialog = false;
              this.existingOrder = "";
              this.statusMessage = "";
            } else {
              this.isOrderEmpty = true;
              const notification = {
                message: `Order ${this.existingOrder} does not exist.`,
                type: "error",
              };
              store.dispatch("notification/add", notification);
            }
          })
          .finally(() => {
            this.isLoadingOrder = false;
            this.isLoadOrderFormSubmitted = false;
          });
      }
    },
    mapExistingOrderToOrder(orderObject: SalesOrder) {
      this.currentOrder.date = orderObject.date;
      for (const [key, value] of Object.entries(orderObject)) {
        this.currentOrder[key] = value;
      }
      this.newSalesOrderCust = orderObject.cust_name as string;
    },
    mapQuoteToOrder(quoteObject: SOQuote, copyOnlyParts = "N") {
      if (copyOnlyParts === "N") {
        this.currentOrder.contact_email = quoteObject.email;
        this.currentOrder.phone = quoteObject.phone;
        this.currentOrder.co_code = quoteObject.co_code;
        this.currentOrder.terms_code = quoteObject.terms;
        this.currentOrder.cust_code = quoteObject.code;
        this.currentOrder.sold_to = quoteObject.customer;
        this.currentOrder.cust_id = quoteObject.customer;
        this.currentOrder.cust_name = quoteObject.name;
        this.newSalesOrderCust = quoteObject.name as string;
        this.formatDate(new Date(), "ORDER_DATE");
      }
      (this.currentOrder.lis_items as unknown) = quoteObject.li_items || [];
      this.formatPartInfo({ ...quoteObject });

      if (
        this.currentOrder.contact_id !== quoteObject.contact &&
        copyOnlyParts === "N"
      ) {
        this.getContact(quoteObject.contact);
        this.newSalesOrderCust = quoteObject.name as string;
      }
    },
    formatPartInfo(object: SOQuote) {
      const reformattedPartInfo: Array<LisItems> = [];

      object.li_items?.forEach(async (qtyObject: LiItems) => {
        const data = {
          number: qtyObject.part,
          quantity: qtyObject.qty_items[0].qty as number,
          date: Utils.formatDate(new Date()),
        };
        await this.addPart({ ...data });
      });
      this.currentOrder.lis_items = reformattedPartInfo;
      this.showAddExistingQuoteDialog = false;
    },
    resetFields(fieldCode: string) {
      switch (fieldCode) {
        case "QUOTE":
          this.isLoadQuoteFormSubmitted = false;
          this.isLoadingQuote = false;
          this.existingSOQuote = "";
          break;
        case "ORDER":
          this.isLoadingOrder = false;
          this.existingOrder = "";
          break;
      }
      this.statusMessage = "";
    },
    focusOrderSearch() {
      (this.$refs.orderInputText as any).$el.focus();
    },
    focusSOQuoteSearch() {
      (this.$refs.soquoteInputText as any).$el.focus();
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      existingSOQuote: {
        required,
      },
      existingOrder: {
        required,
      },
      currentOrder: {
        sold_to: { required },
      },
      newSalesOrderCust: {
        required: helpers.withMessage("Please Select a Customer", required),
      },
      lis_items: {
        required: helpers.withMessage(
          "Please add a part",
          requiredIf(() => {
            return this.currentOrder?.lis_items?.length === 0;
          }),
        ),
      },
      part: {
        number: { required: helpers.withMessage("Part Required", required) },
      },
    };
  },
  inject: ["isProduction"],
  computed: {
    ...mapState(["customerInquiry", "sales"]),
    ...mapGetters({
      shipViaItems: "shipControl/getShipViaItems",
      getOrder: "sales/getOrders",
      getFilters: "control/getFilterItems",
      getCats: "control/getCategoryItems",
      getActiveOrderTab: "salesInquiry/getActiveOrderTab",
      getTypes: "mrkControl/salesOrderTypes",
      getSalesAcct: "mrkControl/salesAcct",
      getCgLoc: "mrkControl/cgLoc",
      getFgLoc: "mrkControl/fgLoc",
      getLoadingPDFs: "sales/getLoadingPDFs",
      getClient: "session/getClient",
      getSelectedCoCode: "customerInquiry/getSelectedCoCode",
      requireCustomerPartsSearch: "mrkControl/requireCustomerPartsSearch",
      getSalesTaxCodes: "stax/getSalesTaxCodes",
      getCustomFields: "fdict/customFields",
    }),
    ...mapStatePinia(useContactControlStore, {
      getContactLookup: "getContactLookup",
    }),
    productionEnvironment(): any {
      return this.isProduction;
    },
    disablePartSearch(): boolean {
      let validCustomer = false;
      if (this.requireCustomerPartsSearch) {
        validCustomer = this.currentOrder.sold_to ? true : false;
      } else {
        validCustomer = true;
      }
      const validCompanyCode = this.currentOrder.co_code ? true : false;
      return !validCompanyCode || !validCustomer;
    },
    customFields(): Array<any> {
      return this.getCustomFields("SO");
    },
    customFieldsLineItems(): Array<any> {
      return this.getCustomFields("SO", FDICT_SO.LIS);
    },
  },
  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showEmailDialog: false,
      allowValidation: false,
      showSearchPartDialog: false,
      partSearch: "",
      parts: [] as Array<any>,
      isSearchingParts: false,
      salesOrderFdict: {} as Fdict,
      part: {
        quantity: 1,
        number: "",
        date: "",
      },
      status: [
        {
          description: "Closed",
          value: "C",
        },
        {
          description: "New",
          value: "N",
        },
        {
          description: "Backorder",
          value: "B",
        },
      ],
      currentId: "",
      currentOrder: {} as SalesOrder,
      customer: {},
      showPartModal: false,
      isLoadingQuote: false,
      isLoadingOrder: false,
      showLoadExistingQuoteBtn: false,
      showAddExistingQuoteDialog: false,
      showAddExistingOrderDialog: false,
      newSalesOrderCust: "",
      newSalesOrderContact: "",
      discount: 0.0,
      isFormSubmitted: false,
      priceCodeParts: [] as Array<any>,
      partLoading: false,
      isOrderEmpty: false,
      statusMessage: "",
      isLoadQuoteFormSubmitted: false,
      isLoadOrderFormSubmitted: false,
      existingSOQuote: "",
      existingOrder: "",
      orderInfoSectionStatus: true,
      poInfoSectionStatus: true,
      priceCodeInfoSectionStatus: true,
      lineInfoSectionStatus: true,
      lastShipInfoSectionStatus: true,
      scheduleDate: "",
      li_ship_dates: [] as Array<any>,
      isLoadingCustomer: false,
      isBillToLoading: false,
      isContactLoading: false,
      submitLoading: false,
      copyOnlyOrderParts: "Y",
      copyOnlyQuoteParts: "Y",
      isProduction: this.isProduction,
    };
  },
});
